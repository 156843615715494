<!--
 * @Description: 可替换物料
 * @Author: ChenXueLin
 * @Date: 2021-10-14 08:59:17
 * @LastEditTime: 2022-11-25 15:54:09
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:operateAction>
          <el-button type="primary" @click="handleAdd" v-has="70"
            >新增</el-button
          >
          <el-button type="primary" @click="changeStatus(1)" v-has="71">
            启用
          </el-button>
          <el-button
            type="primary"
            @click="changeStatus(0)"
            style="margin-right:10px"
            v-has="71"
          >
            停用
          </el-button>
        </template>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="materialNoList">
              <remote-search
                v-model="searchForm.materialNoList"
                :remote="true"
                clearable
                placeholder="物料名称"
                title="物料名称"
                multiple
              ></remote-search>
            </el-form-item>
            <el-form-item class="search-item--1" prop="materialNo">
              <el-input
                v-model="searchForm.materialNo"
                placeholder="物料编码"
                title="物料编码"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item class="search-item--1" prop="model">
              <el-input
                v-model="searchForm.model"
                placeholder="规格型号"
                title="规格型号"
              ></el-input>
            </el-form-item> -->
            <el-form-item class="search-item--1" prop="materialType">
              <e6-vr-select
                is-title
                enterable
                v-model="searchForm.materialType"
                :data="slipperMaterialTypeList"
                placeholder="物料类型"
                title="物料类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="status">
              <e6-vr-select
                v-model="searchForm.status"
                :data="accessoryRelationStatusList"
                placeholder="单据状态"
                title="单据状态"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <!-- <el-form-item class="search-item--1" prop="useScenes">
              <e6-vr-select
                is-title
                v-model="searchForm.useScenes"
                :data="useSceneTypeList"
                placeholder="适用范围"
                title="适用范围"
                multiple
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item> -->
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          ref="tableList"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="80"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.$index + 1 }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
            :class="column.class"
          >
            <template slot-scope="{ row }">
              <div class="table-label" v-if="column.fieldName === 'label'">
                <span
                  v-for="(item, index) in row.label"
                  :key="index"
                  class="label-box"
                  >{{ item }}</span
                >
              </div>
              <span
                v-else-if="column.fieldName === 'status'"
                :class="[
                  'status',
                  row.status === 1
                    ? 'finish'
                    : row.status === 2
                    ? 'ongoing'
                    : ''
                ]"
              >
                已完成
              </span>
              <span v-else-if="column.fieldName === 'workNo'">
                <el-button type="text" @click="handleGoToDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else-if="column.fieldName === 'scheduleId'">
                <el-button type="text" @click="toSchedule(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else-if="column.fieldName === 'scheduleInfoId'">
                <el-button type="text" @click="toScheduleInfoDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <add-recovered-material
      :addVisible.sync="addVisible"
      :dialogType="dialogType"
      @refreshData="queryList"
      :detailInfo="detailInfo"
    ></add-recovered-material>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import addRecoveredMaterial from "./addRecoveredMaterial";
import {
  getRecyclableMaterialList,
  findDownList,
  updateStatusRecyclableMaterial
} from "@/api";
export default {
  name: "recoveredMaterialList",
  data() {
    return {
      loading: false,
      queryListAPI: getRecyclableMaterialList,
      searchForm: {
        materialNoList: [],
        materialNo: "",
        materialType: "",
        useScenes: [],
        status: "",
        pageIndex: 1,
        pageSize: 20
      },
      slipperMaterialTypeList: [], //物料类型
      accessoryRelationStatusList: [
        {
          codeName: "启用",
          codeValue: 1
        },
        {
          codeName: "停用",
          codeValue: 0
        }
      ], //单据状态
      total: 0,
      selColumns: [],
      columnData: [
        {
          fieldName: "materialNo",
          display: true,
          fieldLabel: "物料编码",
          width: 130,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialName",
          display: true,
          fieldLabel: "物料名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "materialTypeName",
          display: true,
          fieldLabel: "物料类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "comments",
          display: true,
          fieldLabel: "备注",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "statusName",
          display: true,
          fieldLabel: "单据状态",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "operator",
          display: true,
          fieldLabel: "操作人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTime",
          display: true,
          fieldLabel: "修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      addVisible: false,
      dialogType: 1,
      detailInfo: {}
    };
  },
  mixins: [listPage, base, listPageReszie],
  components: { addRecoveredMaterial },
  watch: {},
  computed: {},
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        let promiseList = [
          findDownList([
            "slipperMaterialType",
            "accessoryRelationStatus",
            "useSceneType"
          ])
        ];
        let [Res] = await Promise.all(promiseList);
        this.slipperMaterialTypeList = this.getFreezeResponse(Res, {
          path: "data.slipperMaterialType"
        });
        //适用范围
        this.useSceneTypeList = this.getFreezeResponse(Res, {
          path: "data.useSceneType"
        });
        // //单据状态
        // let accessoryRelationStatusList = this.getFreezeResponse(Res, {
        //   path: "data.accessoryRelationStatus"
        // });
        // this.accessoryRelationStatusList = accessoryRelationStatusList.filter(
        //   item => item.codeValue != 3
        // );
      } catch (error) {
        printError(error);
      }
    },
    //多选数据
    handleSelectionChange(columns) {
      this.selColumns = columns;
    },

    // 获取页面操作权限
    getOperateList() {
      let operationIds = this.$route.meta.operationIds || [];
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1,
          disabled: !operationIds.includes(70)
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.dialogType = 2;
        this.addVisible = true;
        this.detailInfo = row;
      }
    },
    //点击添加
    handleAdd() {
      this.addVisible = true;
      this.dialogType = 1;
    },
    //处理上架下架
    changeStatus(status) {
      if (!this.selColumns.length) {
        this.$message.warning("未勾选数据");
        return;
      }
      let tipTitle =
        status == 1
          ? "是否确认启用所勾选的数据？"
          : "是否确认停用所勾选的数据？";

      let title = status == 1 ? "启用" : "停用";
      this.$confirm(tipTitle, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.batchEnable(status);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //批量启用请求
    async batchEnable(status) {
      try {
        this.loading = true;
        let res = await updateStatusRecyclableMaterial({
          recyclableMaterialIdList: this.selColumns.map(
            item => item.recyclableMaterialId
          ),
          status
        });
        if (res.code == "OK") {
          if (status) {
            this.$message.success("启用成功");
          } else {
            this.$message.success("停用成功");
          }
          this.selColumns = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/* 隐藏多选框 */
/deep/.disabled-column .el-checkbox__input {
  display: none !important;
}
</style>
